import React from "react"
import { Link } from "gatsby"

import styled from "styled-components";
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { isMobile } from "react-device-detect";

import Page from "../../components/layout/Page"
import PageHeader from "../../components/layout/PageHeader"
import Container from "../../components/layout/Container"
import SEO from "../../components/seo"
import GoogleImage from "../../components/GoogleImage";

import { devices, storage } from "../../settings"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HoverButton from "../../components/buttons/HoverButton";

const Content0 = styled.div`
    padding: 10px;

    @media ${devices.mobile} {
        padding: 0px;
    } 
`
const Content1 = styled.div`
    padding-left: 20px;

    @media ${devices.tablet} {
        padding-left: 10px;
    }      
    @media ${devices.mobile} {
        padding-left: 0px;
    } 

    p {
        @media ${devices.mobile} {
            margin: 0px;

        }     
    }

    span {
        @media ${devices.mobile} {
            display: none;
        } 
    }
`
const Content2 = styled.div`
    padding-left: 20px;
    background-image: url(${storage.url}2020/tiles/grey3.jpg);
    border-top: 3px solid #e8e8e8;
    margin-top: 80px;

    > div {
        margin-top: -50px;
    }

    @media ${devices.tablet} {
        padding-left: 0px;
    }     
    @media ${devices.mobile} {
        padding-left: 0px;
        margin-left: -10px;
        margin-right: -10px;
    } 
`


const Products = styled.div`
  padding: 20px 25px;
  
  @media ${devices.tablet} {
    padding: 0px 10px;
  }  
`
const Product = styled.section`
  header {
      background-color: #eee;
      box-shadow: 0px 6px 10px #ccc;
      padding: 10px 15px;
      display: flex;
      align-items: baseline;
      border: 1px solid #ccc;
      border-radius: 5px;

      @media ${devices.tablet} {
        display: block;
      }  
      @media ${devices.mobile} {
        display: block;
        background-color: transparent;
        box-shadow: none;
        border: 0px;
      }  

      > * {
        margin: 0px;
      }

      > h2 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
        margin-right: 15px;
        font-size: 1.5em;

        @media ${devices.mobile} {
            font-size: 20px;
        }  
      }
      > p {
          font-size: 18px;

          @media ${devices.mobile} {
            font-size: 14px;
          }  
      }
    
  }

  margin-top: 0px;
  margin-bottom: 25px;

  @media ${devices.mobile} {
    padding: 0px;
    background-color: white;
    box-shadow: 0px 6px 10px #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
  }  

  a {
    color: black;
  }

  > div {
    flex-basis: 50%;
  }
  div.images {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 6px 10px #ccc;
    display: flex;
    justify-content: space-between;
    margin: 0px 50px;

    @media ${devices.tablet} {
        margin: 0px 20px;
    }  
  
    @media ${devices.mobile} {
      display: block;
      margin: 10px 0px 0px 0px;
      border: 0px;
      box-shadow: none;
    }  
  
    > a {
      display: block;
      flex-basis: 47%;
      padding: 30px;      
      
      @media ${devices.mobile} {
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        padding: 10px 0px;   
        border-top: 1px solid #eee;   
      }  

      img {
        display: block;
        margin: 0px auto;
        width: 100%;
        height: auto;

        @media ${devices.mobile} {
          margin: 5px;
          width: 150px;
          height: 75px;
        }        
      }

      > div {
        @media ${devices.mobile} {
            padding: 5px;
        }    
      }
    }

    div.info {
        text-align: right;

        @media ${devices.mobile} {
            display: none;
        }  
    }
    div.arrow {
        display: none;
        padding: 10px;
        font-size: 20px;
        color: #999;

        @media ${devices.mobile} {
            display: block;
        }  
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      margin: 0px;
      text-align: center;

      @media ${devices.mobile} {
        text-align: left;
        font-sizeL 15px;
      }  
    }
    small {
        display: block;
        font-size: 14px;
        color: #999;
        font-weight: 200;
        text-align: center;

        @media ${devices.mobile} {
            text-align: left;
            font-size: 12px;
        }      
    }

  }
`

const LitLettersShapesPage = () => {
  return (
    <>
      <SEO page="products" title="Lit Squared Cabinets" />
      <div>
        <Page 
          transparent
          padding={0}
          breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Products", url:"/products/"},
            {title: "Lit Cabinets", url:null},
          ]}
          containerProps={{
            mode: "full"
          }}
        >
          <Content0>
            <Container>
                <PageHeader
                    title = "Lit Cabinet Signs"
                />
            </Container>
          </Content0>

          <Content1>
            <Container>
            <p>
              Lit Cabinet Signs are ridged signs built as a rectangle or a simple shape, they are different from a Lit Shaped 
              Sign in the way they are constructed. The most unique feature of a Cabinet sign is the Sign Face Retainer. This 
              is a metal piece that is fabricated to the profile of the sign. The metal retainer holds the sign face securely 
              on the sign. This allows the sign to be built much larger due to the face not being held with a trim that is 
              glued to the face. The Cabinet signs are also built of thicker metal and built structurally strong. 
            </p>
            </Container>
          </Content1>

          <Content2>
            <Container>
                <Products>
                    <Product>
                        <header>
                            <h2>Lit Squared Cabinets</h2>
                            <p>
                                Internally Lit with LEDs through the translucent plastic face.
                            </p>
                        </header>
                        <div className="images">
                            <Link to="/products/lit-cabinet-singleface/">
                                <div className="arrow">
                                    <FontAwesomeSvgIcon icon={faChevronRight} />
                                </div>
                                <div>
                                    <h3>Lit Squared Cabinet - Single Faced</h3>
                                    <small>&nbsp;</small>
                                </div>
                                <GoogleImage root src={"2020/products/lit-cabinet-retainer-single/productmed.jpg?v6"} required lazy alt="Lit Cabinet Single Face" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                                <div className="info">
                                    <HoverButton 
                                        color="blue"
                                        label="Learn More"
                                        icon={faChevronRight}
                                    />
                                </div>
                            </Link>
                            <Link to="/products/lit-cabinet-doubleface/">
                                <div className="arrow">
                                    <FontAwesomeSvgIcon icon={faChevronRight} />
                                </div>
                                <div>
                                    <h3>Lit Squared Cabinet - Double Faced</h3>
                                    <small>&nbsp;</small>
                                </div>
                                <GoogleImage root src={"2020/products/lit-cabinet-retainer-double/productmed.jpg?v6"} required lazy alt="Lit Cabinet Double Face" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                                <div className="info">
                                    <HoverButton 
                                        color="blue"
                                        label="Learn More"
                                        icon={faChevronRight}
                                    />
                                </div>
                            </Link>
                        </div>
                    </Product>

                    <Product>
                        <header>
                            <h2>Lit Shape Cabinets</h2>
                            <p>
                                Internally Lit with LEDs through the translucent plastic face.
                            </p>
                        </header>
                        <div className="images">
                            <Link to="/products/lit-cabinet-shape-singleface/">
                                <div className="arrow">
                                    <FontAwesomeSvgIcon icon={faChevronRight} />
                                </div>
                                <div>
                                    <h3>Lit Shape Cabinet - Single Faced</h3>
                                    <small>&nbsp;</small>
                                </div>
                                <GoogleImage root src={"2020/products/lit-cabinet-shape-single/productmed.jpg?v6"} required lazy alt="Lit Cabinet Shape Single Face" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                                <div className="info">
                                    <HoverButton 
                                        color="blue"
                                        label="Learn More"
                                        icon={faChevronRight}
                                    />
                                </div>
                            </Link>
                            <Link to="/products/lit-cabinet-shape-doubleface/">
                                <div className="arrow">
                                    <FontAwesomeSvgIcon icon={faChevronRight} />
                                </div>
                                <div>
                                    <h3>Lit Shape Cabinet - Double Faced</h3>
                                    <small>&nbsp;</small>
                                </div>
                                <GoogleImage root src={"2020/products/lit-cabinet-shape-double/productmed.jpg?v6"} required lazy alt="Lit Cabinet Shape Double Face" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                                <div className="info">
                                    <HoverButton 
                                        color="blue"
                                        label="Learn More"
                                        icon={faChevronRight}
                                    />
                                </div>
                            </Link>
                        </div>
                    </Product>

                </Products>           
            </Container>
          </Content2>

        </Page>
      </div>
    </>
  )
}

export default LitLettersShapesPage
